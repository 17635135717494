import React, { useEffect, useState } from 'react';
import { serviceBundler } from '../../App';
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  List,
  Row,
  Select,
  Space,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import {
  WalletOutlined,
  SettingOutlined,
  RedoOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import style from './BackupPage.module.css';
import { useNavigate } from 'react-router-dom';
import HeaderBar from '../../Components/HeaderBar';
import { RetentionPeriod } from '../../Models/Backup/RetentionPeriod';
import { BackupInterval } from '../../Models/Backup/BackupInterval';
import { BackupConfig } from '../../Models/Backup/BackupConfig';
import { WsHandleBackup } from '../../Components/SoftwareManager/Models/WsHandleBackup';
import { EditConfigBackup } from '../../Components/Backup/EditConfigBackup';
import { RestoreBackup } from '../../Components/Backup/RestoreBackup';
import { Backup } from '../../Models/Backup/Backup';
import { HandleBackUpType } from '../../Components/SoftwareManager/Models/HandleBackUpType';
import { MessageWebSocket } from '../../Models/WebSocket/MessageWebSocket';
import { WebSocketMessageType } from '../../Enums/WebSocketMessageType';
import { WsPublishStatus } from '../../Components/SoftwareManager/Models/WsPublishStatus';
import { WsBackupStatus } from '../../Components/SoftwareManager/Models/WsBackupStatus';
import { useTranslation } from 'react-i18next';

const { useToken } = theme;

const messageValidation = 'Vul a.u.b. dit veld in';

const BackupPage = () => {
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  const [form] = Form.useForm();
  let navigate = useNavigate()

  const [initLoading, setInitLoading] = useState(true);

  const [backupConfig, setBackupConfig] = useState<BackupConfig>();

  const [backups, setBackups] = useState<Backup[]>([]);
  const [backup, setBackup] = useState<Backup>(new Backup());

  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [isModalOpenRestore, setIsModalOpenRestore] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(true);
  const [restoreMode, setRestoreMode] = useState(true);
  const [creatingBackup, setCreatingBackup] = useState(false);

  useEffect(() => {
    serviceBundler.backupService.GetFetchStatus().subscribe((value) => {
      if (!value) {
        return;
      }
      var fetchedBackupConfigs =
        serviceBundler.backupService.GetBackupConfigs();
      var fetchedBackups = serviceBundler.backupService.GetBackups();

      if (fetchedBackupConfigs != undefined) {
          setBackupConfig(fetchedBackupConfigs);
      }

      setBackups(fetchedBackups);
      setInitLoading(false);
    });

    serviceBundler.websocketService.messages.subscribe((msg) => {
      const prodItem = Object.assign(new MessageWebSocket(), msg);
      if (prodItem.Type === WebSocketMessageType.WsCreateBackupResponce) {
        var newItem = Object.assign(new WsBackupStatus(), msg);
        AddBackup(newItem);
      }
    });
  }, [backupConfig, backups, backup]);

  const AddBackup = (item: WsBackupStatus) => {
    var oldBackups = backups;
    var newBackup = new Backup();
    newBackup.id = item.Id;
    newBackup.backupLocation = item.BackupLocation;
    newBackup.created = item.Created;

    oldBackups.push(newBackup);
    setBackups(oldBackups);
    setBackup(newBackup);
    setCreatingBackup(false);
  };

  const HandleBackupModel = () => {
    if (restoreMode) {
      return (
        <RestoreBackup
          open={isModalOpenRestore}
          backup={backup}
          title="Backup herstellen"
          okBtn="Herstellen"
          onCancel={handleCancelRestore}
          onSafe={EnterRestoreBackup}
        ></RestoreBackup>
      );
    }
    return (
      <RestoreBackup
        open={isModalOpenRestore}
        backup={backup}
        title="Backup verwijderen"
        okBtn="Verwijderen"
        onCancel={handleCancelRestore}
        onSafe={EnterRemoveBackup}
      ></RestoreBackup>
    );
  };

  const handleCancelEdit = () => {
    setIsModalOpenEdit(false);
  };

  const handleCancelRestore = () => {
    setIsModalOpenRestore(false);
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const SafeBackup = (item: BackupConfig) => {
    if (item != undefined) {
      if (backupConfig?.id) {
        item.id = backupConfig.id;
        serviceBundler.backupService.UpdateBackup(item);
      }
    }
    setIsModalOpenEdit(false);
  };

  const RestoreBackupClick = (item: Backup) => {
    setBackup(item);
    setRestoreMode(true);
    setIsModalOpenRestore(true);
  };

  const RemoveBackupClick = (item: Backup) => {
    setBackup(item);
    setRestoreMode(false);
    setIsModalOpenRestore(true);
  };

  const EnterRestoreBackup = (item: Backup) => {
    var wsCommand = new WsHandleBackup(HandleBackUpType.RestoreBackup);
    wsCommand.BackupId = item.id;
    serviceBundler.websocketService.sendMessage(wsCommand);
    setIsModalOpenRestore(false);
  };

  const EnterRemoveBackup = (item: Backup) => {
    var wsCommand = new WsHandleBackup(HandleBackUpType.RemoveBackup);
    wsCommand.BackupId = item.id;
    serviceBundler.websocketService.sendMessage(wsCommand);

    setIsModalOpenRestore(false);

    var oldBackups = backups;
    oldBackups.splice(
      oldBackups.findIndex((x) => x.id == item.id),
      1,
    );
    setBackups(oldBackups);
  };

  const CreateBackup = async () => {
    setCreatingBackup(true);
    var wsCommand = new WsHandleBackup(HandleBackUpType.CreateBackup);
    serviceBundler.websocketService.sendMessage(wsCommand);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
      <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentBlock}
        >
          <Col className={style.collapseBlock}>
            <div className={style.contentText}>
              {t('backups')}
              <span className={style.dotColor}>.</span>
            </div>

            <div className={style.formMiddler}>
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => setIsModalOpenEdit(true)}
                    icon={<SettingOutlined />}
                  >
                    {t('setting')}
                  </Button>
                  <Button
                    type="primary"
                    loading={creatingBackup}
                    onClick={CreateBackup}
                  >
                    {t('createbackup')}
                  </Button>
                </div>
                <EditConfigBackup
                  data={backupConfig}
                  open={isModalOpenEdit}
                  onCancel={handleCancelEdit}
                  onSafe={SafeBackup}
                  onEdit={isModelEditMode}
                ></EditConfigBackup>

                {HandleBackupModel()}

                <List
                  style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                  size="small"
                  bordered
                  loading={initLoading}
                  dataSource={backups}
                  renderItem={(item) => (
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div>
                          <WalletOutlined style={{ marginRight: '5px' }} />
                          {item.backupLocation}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div>
                            <Button
                              onClick={() => RemoveBackupClick(item)}
                              ghost
                              icon={<DeleteOutlined />}
                            ></Button>
                          </div>
                          <div>
                            <Button
                              onClick={() => RestoreBackupClick(item)}
                              ghost
                              icon={<RedoOutlined />}
                            ></Button>
                          </div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </>
            </div>
          </Col>
        </Content>
      </Layout>
    </Space>
  );
};

export default BackupPage;
