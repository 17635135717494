import { AuthHelper } from "./Helpers/AuthHelper";
import { AuthData } from "./Models/Auth/AuthData";
import { UserModelSalt } from "./Models/Auth/UserModelSalt";
import { AuthResultFromServer } from "./Providers/AuthResultFromServer";

let authResultFromServer: AuthResultFromServer = new AuthResultFromServer();

const fakeAuthProvider = {
    isAuthenticated: false,
    authResultFromServer: authResultFromServer,

    async signin(authData: UserModelSalt, callback: VoidFunction) {
      var result = await AuthHelper.Login(authData)
      if (result != undefined){
        fakeAuthProvider.isAuthenticated = true;
        authResultFromServer.passwordCorrect = true;
        authResultFromServer.token = result.token;
        authResultFromServer.expiresIn = result.expiresIn;
      }
      else{
        fakeAuthProvider.isAuthenticated = false;
      }
      
      callback()
    },
    signout(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = false;
    },
  }
  
  export { fakeAuthProvider };