import { map, Observable, Observer, Subject } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { MessageWebSocket } from '../Models/WebSocket/MessageWebSocket';
import { IMessageSock } from '../Models/WebSocket/IMessageSock';
import { LocalHelper } from '../Helpers/LocalHelper';

class WebSocketService {
  public prevMessage = new MessageWebSocket();

  private subject?: AnonymousSubject<MessageEvent>;
  public messages: Subject<IMessageSock>;

  private ws: any;

  constructor() {
    var endpointWebsocket = '';
    // if(window.location.href.includes("cloudkwekerijbloemendaal")){
    endpointWebsocket = LocalHelper.GetUrlForSocketRemote();
    // }
    // else{
    //     endpointWebsocket = LocalHelper.GetUrlForSocketLocal()
    // }
    // endpointWebsocket = LocalHelper.GetUrlForSocketLocalHost()

    this.messages = <Subject<IMessageSock>>this.connect(endpointWebsocket).pipe(
      map((response: MessageEvent): IMessageSock => {
        let data = JSON.parse(response.data);
        return data;
      }),
    );
  }

  public connect(url: string): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
    }
    return this.subject;
  }

  private create(url: string): AnonymousSubject<MessageEvent> {
    this.ws = new WebSocket(url);
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      this.ws.onmessage = obs.next.bind(obs);
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = obs.complete.bind(obs);
      return this.ws.close.bind(this.ws);
    });

    let observer = {
      error: () => {},
      complete: () => {},
      next: (data: Object) => {},
    };
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

  public async sendMessage(message: any) {
    await this.ws.send(JSON.stringify(message));
  }

  public close() {
    this.ws.close();
  }
}

export { WebSocketService };
