import { AuthService } from './AuthService';
import { BackupService } from './BackupService';
import { LanguageService } from './LanguageService';
import { SoftwareSettingService } from './SoftwareSettingService';
import { ThemeService } from './ThemeService';
import { UserService } from './UserService';
import { WebSocketService } from './WebSocketService';

class ServiceBundler {
  public themeService = new ThemeService();
  public websocketService = new WebSocketService();
  
  public authService = new AuthService();
  public softwareSettingService = new SoftwareSettingService();
  public userService = new UserService();
  public backupService = new BackupService();
  public languageService = new LanguageService();
}

export { ServiceBundler };
