import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Collapse, Button } from 'antd';
import { CustomPanelLine } from './CustomPanelLine';
import { SoftwareSetting } from '../../../../Models/SoftwareManager/SoftwareSettings';
import { serviceBundler } from '../../../../App';
import {
  PlusCircleOutlined,
  EditOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { WiServiceSetting } from '../../../../Models/SoftwareManager/WiServiceSetting';
import { WsPublishCommand } from '../../Models/WsPublishCommand';
import { AddWiServiceConfig } from '../../forms/AddWiServiceConfig';
import { WsPublishStatus } from '../../Models/WsPublishStatus';
import { PublishSoftwareType } from '../../Models/PublishSoftwareType';
import ProgressWindowsService from '../../timelines/ProgressWiService';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const WiServicePanel = forwardRef(
  (
    baseProps: { softwareSetting: SoftwareSetting; drawerOutput: Function },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      UpdateStatus(status: WsPublishStatus) {
        UpdateProgress(status);
      },

      ResetSteps() {},
    }));

    const { i18n, t } = useTranslation();

    const [isModalOpenWebApi, setIsModalOpenWebApi] = useState(false);
    const [isModelEditModeWebApi, setIsModalEditMode] = useState(false);
    const [serviceSetting, setServiceSetting] = useState<WiServiceSetting>();
    const [softwareSetting, setSoftwareSetting] = useState<SoftwareSetting>(
      new SoftwareSetting(),
    );

    const AddConfiguration = () => {
      setServiceSetting(undefined);
      setIsModalEditMode(false);
      setIsModalOpenWebApi(true);
    };

    const EditConfiguration = (item: WiServiceSetting) => {
      setServiceSetting(item);
      setIsModalEditMode(true);
      setIsModalOpenWebApi(true);
    };

    const ContentPanel = (item: WiServiceSetting) => {
      return (
        <>
          <Button
            type="primary"
            style={{ marginBottom: '10px' }}
            onClick={() => PublishCollection(item)}
          >
            {t('publish')}
          </Button>
          <Button
            type="primary"
            style={{ marginBottom: '10px', marginLeft: '10px' }}
            onClick={() => StartService(item)}
          >
            {t('start')}
          </Button>
          <Button
            type="primary"
            style={{ marginBottom: '10px', marginLeft: '10px' }}
            onClick={() => StopService(item)}
          >
            {t('stop')}
          </Button>
          <ProgressWindowsService ref={childRef}></ProgressWindowsService>
        </>
      );
    };

    const HandleCancelFormWebApi = () => {
      setIsModalOpenWebApi(false);
    };

    const SafeSoftwareSetting = (props: SoftwareSetting) => {
      if (props.id !== undefined) {
        serviceBundler.softwareSettingService.UpdateSoftwareSetting(props);
      } else {
        serviceBundler.softwareSettingService.AddSoftwareSetting(props);
      }
      setIsModalOpenWebApi(false);
    };

    const RemoveSoftwareSetting = (props: SoftwareSetting) => {
      serviceBundler.softwareSettingService.UpdateSoftwareSetting(props);
      setIsModalOpenWebApi(false);
    };

    const RenderDrawerContent = (item: WiServiceSetting) => {
      baseProps.drawerOutput(ContentPanel(item));
    };

    const PublishCollection = (props: WiServiceSetting) => {
      if (baseProps.softwareSetting === undefined) {
        return;
      }

      var newPublishCommand = new WsPublishCommand(PublishSoftwareType.WsPublishWindowService);
      newPublishCommand.SoftwareLabel =
        baseProps.softwareSetting?.softwareLabel;
      newPublishCommand.ItemLabel = props.serviceLabel;
      serviceBundler.websocketService.sendMessage(newPublishCommand);

      const node = childRef.current;
      node!.ResetSteps();
    };

    const StartService = (props: WiServiceSetting) => {

    }

    const StopService = (props: WiServiceSetting) => {

    }

    const childRef = useRef<any>();
    const UpdateProgress = (status: WsPublishStatus) => {
      const node = childRef.current;
      node!.UpdateStatus(status);
    };

    useEffect(() => {
      setSoftwareSetting(baseProps.softwareSetting);
    }, [softwareSetting]);

    return (
      <>
        <AddWiServiceConfig
          allData={baseProps.softwareSetting?.wiServiceSettings}
          parentData={baseProps.softwareSetting}
          data={serviceSetting}
          open={isModalOpenWebApi}
          onCancel={HandleCancelFormWebApi}
          onSafe={SafeSoftwareSetting}
          onRemove={RemoveSoftwareSetting}
          onEdit={isModelEditModeWebApi}
        ></AddWiServiceConfig>
        <Collapse>
          {softwareSetting.wiServiceSettings
            ? softwareSetting?.wiServiceSettings.map(
                (item: WiServiceSetting, i) => (
                  <Panel
                    collapsible={'icon'}
                    header={CustomPanelLine(
                      item.serviceLabel,
                      <ArrowRightOutlined />,
                      <EditOutlined />,
                      RenderDrawerContent,
                      EditConfiguration,
                      item,
                    )}
                    key={i}
                  >
                  </Panel>
                ),
              )
            : []}
        </Collapse>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => AddConfiguration()}
            style={{ marginTop: '10px', marginRight: '17px' }}
            type="primary"
            shape="circle"
            icon={<PlusCircleOutlined />}
          />
        </div>
      </>
    );
  },
);

export { WiServicePanel };
