import { StatusExecuteProcess } from "../Enums/StatusExecuteProcess";
import { WebSocketMessageType } from "../../../Enums/WebSocketMessageType";
import { NameCurrentProcess } from "../Enums/NameCurrentProcess";
import { PublishType } from "../Enums/PublishType";

export class WsPublishStatus{
    public Type?: WebSocketMessageType;
    public SubType?: PublishType;
    public SoftwareLabel?: string
    public Label?: string

    public NameOfProcess?: NameCurrentProcess
    public StatusProcess?: StatusExecuteProcess
    public MessageProcess?: string

    constructor(){
    }
}