import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel } from '../Models/Auth/UserModel';
import { UserHelper } from '../Helpers/UserHelper';
import { serviceBundler } from '../App';
import { AxiosResponse } from 'axios';

export class UserService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public users!: Array<UserModel>;
  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
    this.GetFetchStatus();
  }

  private async FetchItems() {
    var token = serviceBundler.authService.GetToken();

    if (!this.FetchingReady) {
      this.FetchingReady = true;
      this.users = await UserHelper.GetUsers(token);
      this.FetchingReadySubject.next(true);
    }
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject.asObservable();
  }

  // get overview
  public async GetUsers() {
    if (this.users == undefined){
      await this.FetchItems()
    }
    return this.users;
  }

  public async GetUser(id: string): Promise<UserModel> {
    var index = (await this.GetUsers()).findIndex((x) => x.id === id);
    return this.users[index];
  }

  public async GetUserByEmail(email: string): Promise<UserModel> {
    var index = (await this.GetUsers()).findIndex((x) => x.email === email);
    return this.users[index];
  }

  // update overview
  public async UpdateUser(item: UserModel) {
    var token = serviceBundler.authService.GetToken();

    var index = this.users.findIndex((x) => x.id === item.id);
    this.users[index] = item;
    await UserHelper.UpdateUser(item, token);
  }

  public async AddUser(newItem: UserModel) {
    var token = serviceBundler.authService.GetToken();

    var user = await UserHelper.AddUser(newItem, token);
    this.users.push(user);
  }

  public async ConfigUser(newItem: UserModel) {
    var token = serviceBundler.authService.GetToken();
    await UserHelper.ConfigUser(newItem, token);
  }

  public async DeleteUser(item: UserModel) {
    var token = serviceBundler.authService.GetToken();

    var result = await UserHelper.DeleteUser(item, token);
    var axiosResponse = result as AxiosResponse;
    if (axiosResponse.statusText == 'Bad Request') {
      return;
    }

    var index = this.users.findIndex((x) => x.id === item.id);
    this.users.splice(index, 1);
  }
}
