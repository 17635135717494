import { Button, Form, FormInstance, Input, Modal, Select, Space } from 'antd';
import { useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Backup } from '../../Models/Backup/Backup';

const RemoveButton = (props: {
  bindedFunction: Function;
  bindedForm: FormInstance;
}) => {
  return (
    <>
      <Button
        key="link"
        type="primary"
        onClick={() => {
          props.bindedFunction(true);
          props.bindedForm.submit();
        }}
      >
        Verwijder
      </Button>
    </>
  );
};

export function RestoreBackup(props: {
  open: boolean;
  backup: Backup;
  title: string;
  okBtn: string;
  onCancel: Function;
  onSafe: Function;
}) {
  useEffect(() => {}, [0]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = () => {
    props.onSafe(props.backup);
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  return (
    <Modal
      title={props.title}
      open={props.open}
      onOk={() => onFinishForm}
      cancelText="Annuleren"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Annuleer
        </Button>,
        <>
          <Button key="submit" type="primary" onClick={() => onFinishForm()}>
            {props.okBtn}
          </Button>
        </>,
      ]}
    ></Modal>
  );
}
