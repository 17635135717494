import { UserType } from "../../Enums/UserType"

class UserModel{
    id!: string
    email!: string
    name!: string
    userType!: UserType
    configured!: boolean
}

export {UserModel}