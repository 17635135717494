import axios, { AxiosResponse } from 'axios';
import { LocalHelper } from './LocalHelper';

export abstract class HttpHelper {
  public static IsRemoteHosted(): boolean {
    if (window.location.href.includes('cloudkwekerijbloemendaal')) {
    return true;
    } else {
      return false;
    }
  }

  public static GetUrlBaseApi() {
    if (this.IsRemoteHosted()) {
      return LocalHelper.GetUrlForApiRemote();
    } else {
      return LocalHelper.GetUrlForApiLocal();
    }
    return LocalHelper.GetUrlForApiLocalHost();
  }

  public static GetUrlStorageBaseApi() {
    if (window.location.href.includes('cloudkwekerijbloemendaal')) {
      return LocalHelper.GetUrlForStorageRemote();
    } else {
      return LocalHelper.GetUrlForStorageLocal();
    }
  }

  public static async PostRequestForApi<T>(url: string, item: any, token?: string): Promise<T> {
    const respon = axios({
      method: 'post',
      url: url,
      timeout: 4000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(item),
    });

    return respon
      .then((response) => response.data)
      .catch((error) => {
      });
  }

  public static async DeleteRequestForApi<T>(url: string, device: any, token?: string): Promise<T> {
    const respon = axios({
      method: 'delete',
      url: url,
      timeout: 4000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(device),
    });

    return respon
    .then((response) => response.data)
    .catch((error) => {
      return error.response;
    });
  }

  public static async GetRequestForApi<T>(url: string, token?: string): Promise<T>;
  public static async GetRequestForApi<T>(url: string, token?: string): Promise<Array<T>> {
    const respon = axios({
      method: 'get',
      url: url,
      timeout: 4000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return respon
      .then((response) => response.data)
      .catch((error) => {
      });
  }

  public static async UpdateRequestForApi<T>(url: string, device: any, token?: string): Promise<T> {
    const respon = axios({
      method: 'put',
      url: url,
      timeout: 4000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(device),
    });
    return respon
      .then((response) => { response.data})
      .catch((error) => {
        return error.response;
      });
  }

  public static async AuthorizedRequest<T>(url: string, data: any) {
    // let token = this.authService.getToken() |
    let token = '';
    const respon = axios({
      method: 'post',
      url: url,
      timeout: 4000,
      headers: {
        'Access-Control-Allow-Origin': true,
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    });
    return respon
      .then((response) => response.data)
      .catch((error) => {
      });
  }
}
