import { WebSocketMessageType } from "../../../Enums/WebSocketMessageType";
import { HandleBackUpType } from "./HandleBackUpType";

export class WsHandleBackup {
  public Type: WebSocketMessageType;
  public BackupId!: string
  public SubType: HandleBackUpType;


  constructor(type: HandleBackUpType) {
    this.Type = WebSocketMessageType.WsHandleBackup;
    this.SubType = type;
  }
}


