export class WebApiSetting{
    id!: string
    description!: string
    webApiLabel!: string
    sourceRemote!: string
    sourceLocal!: string
    iisSiteName!: string
    buildFolder!: string
    publishSelectionName!: string
    slnName!: string
    endpoint!: string
}