import { Button, Form, FormInstance, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { SoftwareSetting } from '../../../Models/SoftwareManager/SoftwareSettings';
import { useTranslation } from 'react-i18next';

const RemoveButton = (props: {
  bindedFunction: Function;
  bindedForm: FormInstance;
}) => {
  return (
    <>
      <Button
        key="link"
        type="primary"
        onClick={() => {
          props.bindedFunction(true);
          props.bindedForm.submit();
        }}
      >
        Verwijder
      </Button>
    </>
  );
};

export function AddPlatform(props: {
  allData?: SoftwareSetting[];
  data?: SoftwareSetting;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const { t, i18n } = useTranslation();

  const [form] = Form.useForm();

  const [removeItem, setRemoveItem] = useState(false);
  const [setting, setSetting] = useState<SoftwareSetting>();

  useEffect(() => {
    form.resetFields();
    setSetting(props.data);
    setRemoveItem(false);
  }, [form, props.data, setting]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (values: SoftwareSetting) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    }

    if (removeItem) {
      props.onRemove(values);
    } else {
      var rightSetting = props.allData?.find((x) => x.id == values.id);
      if (rightSetting != undefined) {
        rightSetting.description = values.description;
        rightSetting.softwareLabel = values.softwareLabel;
      } else {
        rightSetting = values;
      }
      props.onSafe(rightSetting);
    }
  };

  const checkLabelExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.softwareLabel === value);
    if (foundItem !== undefined) {
      if (foundItem.softwareLabel === props.data?.softwareLabel) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Dit label bestaat al'));
    }
    return Promise.resolve();
  };

  return (
    <>
      <Modal
        title="Voeg een softwareinstelling toe"
        open={props.open}
        onOk={() => form.submit()}
        okText="Opslaan"
        cancelText="Annuleren"
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('cancel')}
          </Button>,
          <>
            {setting?.id !== undefined && (
              <RemoveButton
                bindedFunction={setRemoveItem}
                bindedForm={form}
              ></RemoveButton>
            )}
            <Button key="submit" type="primary" onClick={() => form.submit()}>
              {t('safe')}
            </Button>
          </>,
        ]}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={props.data}
          onFinish={onFinishForm}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Label"
            name="softwareLabel"
            key="softwareLabel"
            rules={[
              {
                required: true,
                message: 'Dit label is vereist',
              },
              { validator: checkLabelExist },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Beschrijving"
            name="description"
            key="description"
            rules={[{ required: true, message: 'Vul een beschrijving in' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
