import { Space, Layout, Row, Col, Divider, Drawer, Button, theme } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { serviceBundler } from '../../../App';
import style from './TranslationsPage.module.css';
import { useParams } from 'react-router-dom';
import HeaderBar from '../../../Components/HeaderBar';
import { Language } from '../../../Models/Language/Language';
import { TableTranslations } from '../../../Components/Test/TableTranslations/TableTranslations';
import { TranslationLabelKey } from '../../../Models/Language/TranslationLabelKey';

import { ArrowLeftOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import { useTranslation } from 'react-i18next';

const { useToken } = theme;

const TranslationsPage = () => {
  const { languageKey } = useParams();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { token } = useToken();

  const [languageTranslations, setLanguageTranslations] = useState<Language>();
  const [searchText, setSearchText] = useState('');

  const childRef = useRef<any>();

  useEffect(() => {
    if (languageKey !== undefined) {
      serviceBundler.languageService.GetFetchStatus().subscribe((value) => {
        if (!value) {
          return;
        }
        var translationKeys =
          serviceBundler.languageService.GetLanguage(languageKey);

        if (translationKeys === undefined) {
          navigate('/languages');
        }
        setLanguageTranslations(translationKeys);
        serviceBundler.languageService.SetCurrentLanguage(translationKeys);
      });
    }
  }, [navigate, languageKey]);

  const NavigateToLanguages = () => {
    navigate('/languages');
  };

  const SafeLanguage = (items: Array<TranslationLabelKey>) => {
    var newData = languageTranslations;

    newData!.keysLabels = items;
    if (newData != undefined) {
      serviceBundler.languageService.UpdateLanguage(newData);
    }
  };

  const GetTranslations = () => {
    return languageTranslations?.keysLabels;
  };

  const onSearch = (value: string) => {
    const node = childRef.current;
    node!.SetSearchText(value);
  };

  const onSearchType = (e: any) => {
    const node = childRef.current;
    node!.SetSearchText(e.target.value);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
      <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token?.colorPrimaryBg }}
          className={style.headerBlock}
        >
          <Col className={style.middleBlock}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={NavigateToLanguages}
              ></Button>
              <Search
                placeholder={t('inputsearch').toString()}
                allowClear
                onChange={onSearchType}
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </div>

            <Divider orientation="left">
              {languageTranslations?.languageName}
            </Divider>

            <TableTranslations
              ref={childRef}
              dataSource={GetTranslations()}
              safeData={SafeLanguage}
            ></TableTranslations>

            <Space
              direction="horizontal"
              style={{
                width: '100%',
                marginTop: '20px',
                justifyContent: 'right',
              }}
            ></Space>
          </Col>
        </Content>
      </Layout>
    </Space>
  );
};

export default TranslationsPage;
