import { HttpHelper } from "./HttpHelper"
import { BackupConfig } from "../Models/Backup/BackupConfig"
import { Backup } from "../Models/Backup/Backup"

export abstract class BackupHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "Backup"
    
    public static async GetBackupConfigs(token: string){
        var urlForRequest = this.baseUrl  + "/Config"
        return (await HttpHelper.GetRequestForApi<BackupConfig>(urlForRequest, token))
    }

    public static async GetBackups(token: string){
        var urlForRequest = this.baseUrl + "/CreatedBackups"
        return (await HttpHelper.GetRequestForApi<Backup[]>(urlForRequest, token))
    }

    public static async AddBackupConfig(item: BackupConfig, token: string){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.PostRequestForApi<BackupConfig>(urlForRequest, item, token))
    }

    public static async UpdateBackup(item: BackupConfig, token: string){
        var urlForRequest = this.baseUrl + "/" + item.id
        return (await HttpHelper.UpdateRequestForApi<BackupConfig>(urlForRequest, item, token))
    }

    public static async DeleteBackup(item: BackupConfig, token: string){
        var urlForRequest = this.baseUrl + "/" + item.id
        return (await HttpHelper.DeleteRequestForApi(urlForRequest, item, token))
    }
}