import { Button, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { SoftwareSetting } from "../../../Models/SoftwareManager/SoftwareSettings";
import { WebApplicationSetting } from "../../../Models/SoftwareManager/WebApplicationSetting";
import { v4 as uuidv4 } from 'uuid';

export function AddWebApplicationConfig(props: {
  allData?: WebApplicationSetting[];
  parentData?: SoftwareSetting;
  data?: WebApplicationSetting;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const [form] = Form.useForm();

  const [removeItem, setRemoveItem] = useState(false);
  const [setting, setSetting] = useState<WebApplicationSetting>();

  const messageValidation = "Vul a.u.b. dit veld in";

  useEffect(() => {
    form.resetFields();
    setSetting(props.data);
    setRemoveItem(false);
  }, [form, props.parentData, props.data, setting]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (data: WebApplicationSetting) => {
    if (props.data?.id !== undefined) {
      data.id = props.data?.id;
    }

    var setting = props.parentData;
    if (setting === undefined) {
      return;
    }

    if (removeItem) {
      var indexForRemove = setting.webApis.findIndex(x => x.webApiLabel === data.webApplicationLabel);
      setting.webApis.splice(indexForRemove, 1)
      props.onRemove(setting);
    } else {
      if (!props.onEdit) {
        if (setting.webApplications === null) {
          setting.webApplications = [data];
        } else {
          if (setting.webApplications == null) {
            setting.webApplications = [data];
          }
          else{
            let myuuid = uuidv4();
            data.id = myuuid;
            setting.webApplications.push(data);
          }
        }
      } else {
        var index = setting.webApplications.findIndex(x => x.id === data.id);
        setting.webApplications[index] = data
      }
      props.onSafe(setting);
    }
  };

  const onFinishFormFailed = (errorInfo: any) => {
  };

  const checkPrice = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.webApplicationLabel === value);

    if (foundItem !== undefined) {
      if (foundItem.webApplicationLabel === props.data?.webApplicationLabel) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Dit label bestaat al"));
    }
    return Promise.resolve();
  };

  return (
    <Modal
      title="Voeg een web applicatie configuratie toe"
      open={props.open}
      onOk={() => form.submit()}
      okText="Opslaan"
      cancelText="Annuleren"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Annuleer
        </Button>,

        <Button
          key="link"
          type="primary"
          onClick={() => {
            setRemoveItem(true);
            form.submit();
          }}
        >
          Verwijder
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Opslaan
        </Button>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600, height: "auto" }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Label"
          name="webApplicationLabel"
          rules={[
            {
              required: true,
              message: messageValidation,
            },
            { validator: checkPrice },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Beschrijving"
          name="description"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Online source (url)"
          name="sourceRemote"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Lokaal source (map)"
          name="sourceLocal"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Build map"
          name="buildDirectory"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Kopieer map"
          name="copyDirectory"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Naam IIS site"
          name="iisSiteName"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
