import { Button, Result } from "antd";
import { ResultStatusType } from "antd/es/result";

const RenderResult = (
    typeAlert: ResultStatusType,
    title: string,
    subtitle: string,
    BindedFunctionButtonFirst: Function,
    textButtonFirst: string,
    BindedFunctionButtonSecond: Function,
    textButtonSescond: string,
  ) => {
    return (
      <Result
        status={typeAlert}
        title={title}
        subTitle={subtitle}
        extra={[
          <Button type="primary" key="console" onClick={() => BindedFunctionButtonFirst()}>
            {textButtonFirst}
          </Button>,
          <Button  key="console" onClick={() => BindedFunctionButtonSecond()}>
            {textButtonSescond}
          </Button>,
        ]}
      />
    );
  };

  export default RenderResult;