import {
  Collapse,
  Space,
  Layout,
  Row,
  Col,
  Divider,
  Button,
  Typography,
} from 'antd';
import { ReactElement } from 'react';

const { Text } = Typography;

const CustomPanelLine = (
  item: string,
  iconFirst: any,
  iconSecond: any,
  bindedFunctionNavi: Function,
  bindedFunctionEdit: Function,
  argument?: any,
) => {
  return (
    <div
      style={{
        height: '15px',
        marginTop: '0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <>
        <Text>{item}</Text>
        <div>
          <Button
            onClick={() => bindedFunctionNavi(argument)}
            style={{ marginTop: '-5px', marginRight: '10px' }}
            type="primary"
            shape="circle"
            icon={iconFirst}
          />
          <Button
            onClick={() => bindedFunctionEdit(argument)}
            style={{ marginTop: '-5px' }}
            type="primary"
            shape="circle"
            icon={iconSecond}
          />
        </div>
      </>
    </div>
  );
};

export { CustomPanelLine };
