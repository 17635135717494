import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { StepProps, Steps, Timeline } from "antd";

import { StatusExecuteProcess } from "../Enums/StatusExecuteProcess";
import { WsPublishStatus } from "../Models/WsPublishStatus";
import { NameCurrentWebApp } from "../Enums/NameCurrentWebApp";
import { NameCurrentProcess } from "../Enums/NameCurrentProcess";

const ProgressWebApp = forwardRef((props : {}, ref) => {

  useImperativeHandle(ref, () => ({
    UpdateStatus(status: WsPublishStatus) {
      let newArr = [...items];

      var indexOfEnum = 0
      if (status.NameOfProcess != undefined) {
        var allValues = Object.values(NameCurrentProcess)
        var indexOfEnum = Object.values(NameCurrentWebApp).indexOf(allValues[status.NameOfProcess].toString())
      }

      if (indexOfEnum == -1){
        return;
      }

      var b = newArr[indexOfEnum]
      if (status.StatusProcess === StatusExecuteProcess.Started){
        b.status = "wait";
        b.description = status.MessageProcess;
      }
      if (status.StatusProcess === StatusExecuteProcess.Finished){
        b.status = "finish";
        b.description = status.MessageProcess;
      }
      if (status.StatusProcess === StatusExecuteProcess.Failed){
        b.status = "error";
        b.description = status.MessageProcess;
      }

      setItems(newArr)
    },

    ResetSteps(){
      setItems(listSteps)
    }
  }))

  const listSteps: StepProps[] = [
    {
      title: "Proces starten",
      status: "wait",
    },
    {
      title: "Nieuwe versie ophalen",
      status: "wait",
    },
    {
      title: "IIS site stoppen",
      status: "wait",
    },
    {
      title: "Source bouwen",
      status: "wait",
    },
    {
      title: "Folder wissen",
      status: "wait",
    },
    {
      title: "Kopieeren naar map",
      status: "wait",
    },
    {
      title: "IIS site starten",
      status: "wait",
    },
  ]

  const [items, setItems] = useState<StepProps[]>(listSteps);

  useEffect(() => {

  })

  return (
    <div>
      <Steps
        progressDot
        current={1}
        direction="vertical"
        items={items}
      />
    </div>
  );
});

export default ProgressWebApp;
