import { UserType } from "../Enums/UserType"
import { ResetPasswordResponce } from "../Models/Auth/ResetPasswordResponce"
import { UserModel } from "../Models/Auth/UserModel"
import { SoftwareSetting } from "../Models/SoftwareManager/SoftwareSettings"
import { HttpHelper } from "./HttpHelper"

export abstract class PasswordResetHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "User"
    
    public static async ValidateUniqueToken(token: string){
        var urlForRequest = this.baseUrl
        return await HttpHelper.GetRequestForApi<UserModel>(urlForRequest, token)
    }

    public static async ResetPassword(email: string){
        var item = new UserModel();
        item.name = "";
        item.email = email;
        item.userType = UserType.NormalUser;
        var urlForRequest = this.baseUrl + "/resetPassword"
        return (await HttpHelper.PostRequestForApi<UserModel>(urlForRequest, item))
    }

    public static async UpdatePassword(resetPassword: ResetPasswordResponce){
        var urlForRequest = this.baseUrl + "/UpdatePassword"
        return (await HttpHelper.PostRequestForApi<ResetPasswordResponce>(urlForRequest, resetPassword))
    }
}