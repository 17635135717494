import { WiServiceSetting } from "./WiServiceSetting"
import { WebApiSetting } from "./WebApiSetting"
import { WebApplicationSetting } from "./WebApplicationSetting"
import { NdServiceSetting } from "./NdServiceSetting"

export class SoftwareSetting{
    id!: string
    softwareLabel!: string
    description!: string

    webApis!: Array<WebApiSetting>
    webApplications!: Array<WebApplicationSetting>
    wiServiceSettings!: Array<WiServiceSetting>
    ndServiceSettings!: Array<NdServiceSetting>
}