import React, { useEffect, useState } from 'react';
import { serviceBundler } from '../../App';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Layout,
  List,
  Skeleton,
  Space,
  message,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import {
  PlusCircleOutlined,
  EditOutlined,
  UserOutlined,
} from '@ant-design/icons';

import style from './UserDashboard.module.css';
import { useNavigate } from 'react-router-dom';
import { UserModel } from '../../Models/Auth/UserModel';
import { UserType } from '../../Enums/UserType';
import { AddUser } from '../../Components/UserDashboard/Forms/AddUser';
import HeaderBar from '../../Components/HeaderBar';
import RenderResult from '../../Components/Result/Result';
import { useTranslation } from 'react-i18next';

const { useToken } = theme;

const UserDashboard = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const [initLading, setInitLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [configUser, setConfigUser] = useState(false);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [user, setUser] = useState<UserModel>();

  useEffect(() => {
    serviceBundler.userService.GetFetchStatus().subscribe((value) => {
      if (!value) {
        return;
      }
      RetrieveUsers();
    });
  }, [users]);

  const RetrieveUsers = async () => {
    var retrievedUsers = await serviceBundler.userService.GetUsers();
    if (retrievedUsers !== undefined) {
      setUsers(retrievedUsers);
      setInitLoading(false);
    }
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const ConfigUser = async (props: UserModel) => {
    setConfigUser(true);
    await serviceBundler.userService.ConfigUser(props);
  };

  const SafeUser = async (props: UserModel) => {
    setActionLoading(true);
    if (props.id !== undefined) {
      await serviceBundler.userService.UpdateUser(props);
    } else {
      if (serviceBundler.userService.users == null) {
        serviceBundler.userService.users = [props];
      } else {
        await serviceBundler.userService.AddUser(props);
      }
    }
    setActionLoading(false);
    setIsModalOpen(false);
  };

  const RemoveUser = async (props: UserModel) => {
    if (
      users.filter((x) => x.userType == UserType.AdminUser).length <= 1 &&
      props.userType == UserType.AdminUser
    ) {
      message.error('Er is minimaal 1 adminstrator nodig');
    }
    var result = await serviceBundler.userService.DeleteUser(props);
    setIsModalOpen(false);
  };

  const showModalForEdit = (item: UserModel) => {
    setUser(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const showModalForAdd = () => {
    setUser(new UserModel());
    setIsModalOpen(true);
    setModelEditMode(false);
  };

  const RenderConfigUser = () => {
    return RenderResult(
      'info',
      'Link verstuurd',
      'U kunt nu wachtwoord instellen in uw mail',
      BackToLogin,
      'Ga verder',
      BackToHome,
      t('backtohome'),
    );
  };

  const BackToLogin = () => {
    navigate('/login');
  };

  const BackToHome = () => {
    navigate('/');
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentBlock}
        >
          <Col className={style.collapseBlock}>
            {configUser && RenderConfigUser()}

            {!configUser && (
              <>
                <Divider orientation="left">{t('allusers')}</Divider>
                <List
                  loading={initLading}
                  className="demo-loadmore-list"
                  itemLayout="horizontal"
                  dataSource={users}
                  renderItem={(item) => (
                    <List.Item
                      key={item.id}
                      actions={[
                        <Button
                          onClick={() => showModalForEdit(item)}
                          style={{ marginTop: '-5px' }}
                          type="primary"
                          shape="circle"
                          icon={<EditOutlined />}
                        ></Button>,
                      ]}
                    >
                      <Skeleton avatar title={false} loading={false} active>
                        <List.Item.Meta
                          avatar={<Avatar icon={<UserOutlined />} />}
                          title={<a href="https://ant.design">{item.name}</a>}
                        />
                      </Skeleton>
                    </List.Item>
                  )}
                />
                <Space
                  direction="horizontal"
                  style={{
                    width: '100%',
                    marginTop: '20px',
                    justifyContent: 'right',
                  }}
                >
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={showModalForAdd}
                  >
                    {t('addauser')}
                  </Button>
                  <AddUser
                    allData={users}
                    data={user}
                    open={isModalOpen}
                    onCancel={handleCancelForm}
                    onSafe={SafeUser}
                    onRemove={RemoveUser}
                    onEdit={isModelEditMode}
                    actionLoading={actionLoading}
                    configUser={ConfigUser}
                  ></AddUser>
                </Space>
              </>
            )}
          </Col>
        </Content>
      </Layout>
    </Space>
  );
};

export default UserDashboard;
