import { Collapse, Space, Layout, Col, Divider, Drawer, theme } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { serviceBundler } from '../../../App';
import HeaderBar from '../../HeaderBar';
import style from './SoftwareSystem.module.css';
import { useParams } from 'react-router-dom';
import { MessageWebSocket } from '../../../Models/WebSocket/MessageWebSocket';
import { WebSocketMessageType } from '../../../Enums/WebSocketMessageType';
import { PublishType } from '../Enums/PublishType';
import { WebApiPanel } from './panels/WebApiPanel';
import { WebApplicationPanel } from './panels/WebAppPanel';
import { WiServicePanel } from './panels/WiServicePanel';
import { SoftwareSetting } from '../../../Models/SoftwareManager/SoftwareSettings';
import { WsPublishStatus } from '../Models/WsPublishStatus';
import { NodeServicePanel } from './panels/NodeServicePanel';

const { useToken } = theme;
const { Panel } = Collapse;

const SoftwareSystem = () => {
  const { softwareName } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();

  const childRefApi = useRef<any>();
  const childRefAppl = useRef<any>();
  const childRefWindowsService = useRef<any>();
  const childRefNodeService = useRef<any>();

  const [softwareSetting, setSoftwareSetting] = useState<SoftwareSetting>(
    new SoftwareSetting(),
  );

  const [element, setElement] = useState(<></>);

  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };

  const UpdateWebApiProgress = (status: WsPublishStatus) => {
    const node = childRefApi.current;
    node!.UpdateStatus(status);
  };

  const UpdateWebApplicationProgress = (status: WsPublishStatus) => {
    const node = childRefAppl.current;
    node!.UpdateStatus(status);
  };

  const UpdateWiServiceProgress = (status: WsPublishStatus) => {
    const node = childRefWindowsService.current;
    node!.UpdateStatus(status);
  };

  const UpdateNodeServiceProgress = (status: WsPublishStatus) => {
    const node = childRefNodeService.current;
    node!.UpdateStatus(status);
  };

  const setDrawerOutput = (element: ReactElement) => {
    setElement(element);
    showDrawer();
  };

  useEffect(() => {
    if (softwareName !== undefined) {
      serviceBundler.softwareSettingService
        .GetFetchStatus()
        .subscribe((value) => {
          if (!value) {
            return;
          }
          var specificSetting =
            serviceBundler.softwareSettingService.GetSoftwareSetting(
              softwareName,
            );

          if (specificSetting === undefined) {
            navigate('/softwaremanager');
          }
          setSoftwareSetting(specificSetting);
        });

      serviceBundler.websocketService.messages.subscribe((msg) => {
        const prodItem = Object.assign(new MessageWebSocket(), msg);
        if (prodItem.Type === WebSocketMessageType.WsPublishStatus) {
          const publishItem = Object.assign(new WsPublishStatus(), msg);
          if (publishItem.SubType == PublishType.WsPublishWebApiStatus) {
            UpdateWebApiProgress(publishItem);
          } else if (
            publishItem.SubType === PublishType.WsPublishWebAppStatus
          ) {
            UpdateWebApplicationProgress(publishItem);
          } else if (
            publishItem.SubType === PublishType.WsPublishWindowsServiceStatus
          ) {
            UpdateWiServiceProgress(publishItem);
          } else if (
            publishItem.SubType === PublishType.WsPublishNodeServiceStatus
          ) {
            UpdateNodeServiceProgress(publishItem);
          }
        }
      });
    }
  }, [navigate, softwareName]);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
      <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token?.colorPrimaryBg }}
          className={style.headerBlock}
        >
          <Col className={style.middleBlock}>
            <Divider orientation="left">
              {softwareSetting?.softwareLabel}
            </Divider>

            <Collapse className={style.collapseBlock}>
              <Panel header={'Web Api'} key={'webapi'}>
                <WebApiPanel
                  ref={childRefApi}
                  drawerOutput={setDrawerOutput}
                  softwareSetting={softwareSetting}
                ></WebApiPanel>
              </Panel>
              <Panel header={'Web application'} key={'webapplication'}>
                <WebApplicationPanel
                  ref={childRefAppl}
                  drawerOutput={setDrawerOutput}
                  softwareSetting={softwareSetting}
                ></WebApplicationPanel>
              </Panel>
              <Panel header={'Windows services'} key={'windowservices'}>
                <WiServicePanel
                  ref={childRefWindowsService}
                  drawerOutput={setDrawerOutput}
                  softwareSetting={softwareSetting}
                ></WiServicePanel>
              </Panel>
              <Panel header={'Node services'} key={'nodeservices'}>
                <NodeServicePanel
                  ref={childRefNodeService}
                  drawerOutput={setDrawerOutput}
                  softwareSetting={softwareSetting}
                ></NodeServicePanel>
              </Panel>
            </Collapse>

            <Drawer
              width={400}
              placement="right"
              closable={true}
              onClose={onClose}
              open={openDrawer}
            >
              {element}
            </Drawer>

            <Space
              direction="horizontal"
              style={{
                width: '100%',
                marginTop: '20px',
                justifyContent: 'right',
              }}
            ></Space>
          </Col>
        </Content>
      </Layout>
    </Space>
  );
};

export default SoftwareSystem;
