import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Collapse, Button } from 'antd';
import { CustomPanelLine } from './CustomPanelLine';
import { SoftwareSetting } from '../../../../Models/SoftwareManager/SoftwareSettings';
import { serviceBundler } from '../../../../App';
import {
  PlusCircleOutlined,
  EditOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { WsPublishStatus } from '../../Models/WsPublishStatus';
import { AddNdServiceConfig } from '../../forms/AddNdServiceConfig';
import { NdServiceSetting } from '../../../../Models/SoftwareManager/NdServiceSetting';
import { WsPublishCommand } from '../../Models/WsPublishCommand';
import { PublishSoftwareType } from '../../Models/PublishSoftwareType';
import ProgressNodeService from '../../timelines/ProgressNodeService';

const { Panel } = Collapse;

const NodeServicePanel = forwardRef(
  (
    baseProps: { softwareSetting: SoftwareSetting; drawerOutput: Function },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      UpdateStatus(status: WsPublishStatus) {
        UpdateProgress(status);
      },

      ResetSteps() {},
    }));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModelEditMode, setIsModalEditMode] = useState(false);
    const [serviceSetting, setServiceSetting] = useState<NdServiceSetting>();
    const [softwareSetting, setSoftwareSetting] = useState<SoftwareSetting>(
      new SoftwareSetting(),
    );

    const ContentPanel = (item: NdServiceSetting) => {
      return (
        <>
          <Button
            type="primary"
            style={{ marginBottom: '10px' }}
            onClick={() => PublishCollection(item)}
          >
            Publiceren
          </Button>
          <ProgressNodeService ref={childRef}></ProgressNodeService>
        </>
      );
    };

    const RenderDrawerContent = (item: NdServiceSetting) => {
      baseProps.drawerOutput(ContentPanel(item));
    };

    const AddConfiguration = () => {
      setServiceSetting(undefined);
      setIsModalEditMode(false);
      setIsModalOpen(true);
    };

    const EditConfiguration = (item: NdServiceSetting) => {
      setServiceSetting(item);
      setIsModalEditMode(true);
      setIsModalOpen(true);
    };

    const HandleCancelFormWebApi = () => {
      setIsModalOpen(false);
    };

    const SafeSoftwareSetting = (props: SoftwareSetting) => {
      if (props.id !== undefined) {
        serviceBundler.softwareSettingService.UpdateSoftwareSetting(props);
      } else {
        serviceBundler.softwareSettingService.AddSoftwareSetting(props);
      }
      setIsModalOpen(false);
    };

    const RemoveSoftwareSetting = (props: SoftwareSetting) => {
      serviceBundler.softwareSettingService.UpdateSoftwareSetting(props);
      setIsModalOpen(false);
    };

    const PublishCollection = (props: NdServiceSetting) => {
      if (baseProps.softwareSetting === undefined) {
        return;
      }

      var newPublishCommand = new WsPublishCommand(
        PublishSoftwareType.WsPublishNodeService,
      );
      newPublishCommand.SoftwareLabel =
        baseProps.softwareSetting?.softwareLabel;
      newPublishCommand.ItemLabel = props.serviceLabel;
      serviceBundler.websocketService.sendMessage(newPublishCommand);

      const node = childRef.current;
      node!.ResetSteps();
    };

    const childRef = useRef<any>();
    const UpdateProgress = (status: WsPublishStatus) => {
      const node = childRef.current;
      node!.UpdateStatus(status);
    };

    useEffect(() => {
      setSoftwareSetting(baseProps.softwareSetting);
    }, [softwareSetting]);

    return (
      <>
        <AddNdServiceConfig
          allData={baseProps.softwareSetting?.ndServiceSettings}
          parentData={baseProps.softwareSetting}
          data={serviceSetting}
          open={isModalOpen}
          onCancel={HandleCancelFormWebApi}
          onSafe={SafeSoftwareSetting}
          onRemove={RemoveSoftwareSetting}
          onEdit={isModelEditMode}
        ></AddNdServiceConfig>
        <Collapse>
          {softwareSetting.ndServiceSettings
            ? softwareSetting?.ndServiceSettings.map(
                (item: NdServiceSetting, i) => (
                  <Panel
                    collapsible={'icon'}
                    header={CustomPanelLine(
                      item.serviceLabel,
                      <ArrowRightOutlined />,
                      <EditOutlined />,
                      RenderDrawerContent,
                      EditConfiguration,
                      item,
                    )}
                    key={i}
                  ></Panel>
                ),
              )
            : []}
        </Collapse>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => AddConfiguration()}
            style={{ marginTop: '10px', marginRight: '17px' }}
            type="primary"
            shape="circle"
            icon={<PlusCircleOutlined />}
          />
        </div>
      </>
    );
  },
);

export { NodeServicePanel };
