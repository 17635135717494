export enum NameCurrentProcess{
    ProcessStart,
    FetchFromGit,
    StopIISSite,
    PublishToDirectory,
    StartIISSite,
    NpmRunBuild,
    StopService,
    StartService,
    FolderClear,
    FolderCopy
}