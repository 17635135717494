import { Button, Col, Form, Input, Layout, Space, theme } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';
import { EditConfigBackup } from '../../Components/Backup/EditConfigBackup';
import { RestoreBackup } from '../../Components/Backup/RestoreBackup';
import HeaderBar from '../../Components/HeaderBar';

import style from './ContactPage.module.css';
import { useState } from 'react';
import RenderResultBare from '../../Components/Result/ResultBare';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography/Typography';

const { useToken } = theme;

const validateMessages = {
  required: '${label} is vereist',
  types: {
    email: 'Voer een geldige email in',
  },
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const ContactPage = () => {
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  const [form] = Form.useForm();

  const [sendContact, setSendContact] = useState(false);

  const SendContactMessage = () => {
    setSendContact(true);
  };

  const RenderContactSucces = () => {
    return RenderResultBare(
      'success',
      'Formulier verstuurd',
      'Uw bericht wordt zo snel mogelijk beantwoord',
    );
  };

  const onFinish = (values: any) => {
    SendContactMessage();
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentBlock}
        >
          <Col className={style.middleBlock}>
            {sendContact && RenderContactSucces()}

            {!sendContact && (
              <>
                <div className={style.contentText}>
                  <div className={style.marginContact}>
                    <Typography>{t('contact')}</Typography>
                  </div>
                </div>
                <Form
                  name="basic"
                  {...layout}
                  className={style.formWidth}
                  onFinish={onFinish}
                  form={form}
                  validateMessages={validateMessages}
                >
                  <Form.Item
                    name={['user', 'name']}
                    label="Naam"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['user', 'email']}
                    label="Email"
                    rules={[{ type: 'email', required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['user', 'introduction']}
                    label="Bericht"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea style={{minHeight:100}} />
                  </Form.Item>
                </Form>
                <div className={style.btnSubmit}>
                  <div></div>
                  <Button type="primary" onClick={() => form.submit()}>
                    {t('send')}
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Content>
      </Layout>
    </Space>
  );
};

export default ContactPage;
