import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../Providers/AuthProvider';
import {
  Row,
  Col,
  Space,
  Layout,
  Button,
  Checkbox,
  Form,
  Input,
  Alert,
  Result,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import HeaderBar from '../../Components/HeaderBar';

import style from './ResetPassword.module.css';
import { AuthData } from '../../Models/Auth/AuthData';
import { AuthResultFromServer } from '../../Providers/AuthResultFromServer';
import { useEffect, useState } from 'react';
import Link from 'antd/es/typography/Link';
import { serviceBundler } from '../../App';
import { PasswordResetHelper } from '../../Helpers/PasswordResetHelper';
import { ResetPasswordResponce } from '../../Models/Auth/ResetPasswordResponce';
import { ResultStatusType } from 'antd/es/result';
import RenderResult from '../../Components/Result/Result';

interface NewPassword {
  password: string;
  conformPassword: string;
}

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 120,
  width: '100%',
  paddingInline: 0,
  backgroundColor: 'white',
};

interface AuthFormData {
  email: string;
  password: string;
}

const ResetPassword = () => {
  const [form] = Form.useForm();
  const { UniqueToken } = useParams();

  const [firstPassword, setFirstPassword] = useState('');
  const [alertPasswordReset, showAlertPasswordReset] = useState(false);
  const [resetPasswordFailed, setResetPasswordFailed] = useState(false);

  let navigate = useNavigate();

  const CaptureFirstPassword = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFirstPassword(e.target.value);
  };

  const handleSubmit = async (values: NewPassword) => {
    var newResetPassword = new ResetPasswordResponce();
    newResetPassword.uniqueToken = UniqueToken?.toString() ? UniqueToken : '';
    newResetPassword.newPassword = values.password;
    var result = await PasswordResetHelper.UpdatePassword(newResetPassword);
    if (result == undefined) {
      showAlertPasswordReset(true);
      setResetPasswordFailed(true);
    }
    showAlertPasswordReset(true);
  };

  const onFinish = (values: NewPassword) => {
    handleSubmit(values);
  };

  const onFinishFailed = (errorInfo: any) => {};

  const checkLabelExist = (_: any, value: string) => {
    if (value !== firstPassword) {
      return Promise.reject(new Error('De wachtwoorden komen niet overeen'));
    }
    return Promise.resolve();
  };

  const BackToLogin = () => {
    navigate('/login');
  };

  const BackToHome = () => {
    navigate('/');
  };

  const RenderPasswordAlert = () => {
    if (resetPasswordFailed) {
      return RenderResult(
        'error',
        'Het verzoek is verlopen',
        'Vraag een nieuw verzoek aan',
        BackToLogin,
        'Ga verder',
        BackToHome,
        'Ga naar home',
      );
    }
    return RenderResult(
      'success',
      'Het wachtwoord is gereset',
      '',
      BackToLogin,
      'Inloggen',
      BackToHome,
      'Ga naar home',
    );
  };

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', height: '100vh' }}
      size={[0, 48]}
    >
      <Layout>
        <Header style={headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content className={style.headerBlock}>
          <Row>
            <Col span={12} offset={6} className={style.middleBlock}>
              {alertPasswordReset && RenderPasswordAlert()}
              {!alertPasswordReset && (
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ width: 500, margin: 'auto' }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
                >
                  <Form.Item
                    label="Wachtwoord"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Geef je wachtwoord',
                      },
                    ]}
                  >
                    <Input.Password onChange={CaptureFirstPassword} />
                  </Form.Item>
                  <Form.Item
                    label="Bevestig wachtwoord"
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Bevestig je wachtwoord',
                      },
                      { validator: checkLabelExist },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                      Resetten
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </Space>
  );
};

export default ResetPassword;
