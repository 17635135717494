import React, { useEffect } from 'react';
import { Layout, Space, theme, Typography } from 'antd';

import style from './HomePage.module.css';

import { Header } from 'antd/es/layout/layout';
import { LocalHelper } from '../../Helpers/LocalHelper';
import HeaderBar from '../../Components/HeaderBar';
import { useTranslation } from 'react-i18next';
import Title from 'antd/es/typography/Title';

const { useToken } = theme;
const { Content } = Layout;

function GetRemoteLocationUrl(): boolean {
  if (window.location.href.includes('cloudkwekerijbloemendaal')) {
    return false;
  } else {
    return true;
  }
}

function PlatFormLink() {
  window.location.href = LocalHelper.GetPlatformLink(GetRemoteLocationUrl());
}

function UseMobile() {
  const { innerWidth: width, innerHeight: height } = window;
  if (width > 500) {
    return true;
  }
  return false;
}

const Home = () => {
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  useEffect(() => {}, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentPage}
        >
          <div className={style.contentDevider}>
            <div className={style.leftBlock}>
              <div className={style.textLeftBlock}>
                {t('mainall')} <br></br>
                {t('mainsystems')}
                <span className={style.dotColor}>.</span>
              </div>

              {UseMobile() && (
                <div className={style.marginPlayButton}>
                  <div>
                    <a href="https://play.google.com/store/apps/details?id=com.kwebloapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                      <img
                        className={style.googlePlayButton}
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      />
                    </a>
                  </div>
                  <Typography>
                    {t('androidbatch1')} <br />
                    {t('androidbatch2')}
                  </Typography>
                </div>
              )}
            </div>

            <div className={style.rightBlock}>
              <div className={style.item} onClick={() => PlatFormLink()}>
                  Platformen
              </div>
            </div>
          </div>
        </Content>
        {/* <Footer style={footerStyle}>Footer</Footer> */}
      </Layout>
    </Space>
  );
};

export default Home;
