import { SoftwareSetting } from "../Models/SoftwareManager/SoftwareSettings"
import { HttpHelper } from "./HttpHelper"

export abstract class SoftwareSettingHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "SoftwareSetting"
    
    public static async GetSoftwareSetting(){
        var urlForRequest = this.baseUrl
        return await HttpHelper.GetRequestForApi<SoftwareSetting[]>(urlForRequest)
    }

    public static async AddSoftwareSetting(item: SoftwareSetting){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.PostRequestForApi<SoftwareSetting>(urlForRequest, item))
    }

    public static async UpdateSoftwareSetting(item: SoftwareSetting){
        var urlForRequest = this.baseUrl + "/" + item.id
        return (await HttpHelper.UpdateRequestForApi<SoftwareSetting>(urlForRequest, item))
    }

    public static async DeleteSoftwareSetting(item: SoftwareSetting){
        var urlForRequest = this.baseUrl + "/" + item.id
        return (await HttpHelper.DeleteRequestForApi(urlForRequest, item))
    }
}