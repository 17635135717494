import { Button, Form, Input, Modal, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { SoftwareSetting } from '../../../Models/SoftwareManager/SoftwareSettings';
import { WiServiceSetting } from '../../../Models/SoftwareManager/WiServiceSetting';
import { v4 as uuidv4 } from 'uuid';

export function AddWiServiceConfig(props: {
  allData?: WiServiceSetting[];
  parentData?: SoftwareSetting;
  data?: WiServiceSetting;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const [form] = Form.useForm();

  const [removeItem, setRemoveItem] = useState(false);
  const [setting, setSetting] = useState<WiServiceSetting>();

  const messageValidation = 'Vul a.u.b. dit veld in';

  useEffect(() => {
    form.resetFields();
    setSetting(props.data);
    setRemoveItem(false);
  }, [form, props.parentData, props.data, setting]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (data: WiServiceSetting) => {
    if (props.data?.id !== undefined) {
      data.id = props.data?.id;
    }

    var setting = props.parentData;
    if (setting === undefined) {
      return;
    }

    if (removeItem) {
      var indexForRemove = setting.wiServiceSettings.findIndex(
        (x) => x.serviceLabel === data.serviceLabel,
      );
      setting.wiServiceSettings.splice(indexForRemove, 1);
      props.onRemove(setting);
    } else {
      if (!props.onEdit) {
        //add
        if (setting.wiServiceSettings === null) {
          setting.wiServiceSettings = [data];
        } else {
          if (setting.wiServiceSettings == null) {
            setting.wiServiceSettings = [data];
          } else {
            let myuuid = uuidv4();
            data.id = myuuid;
            setting.wiServiceSettings.push(data);
          }
        }
      } else {
        var index = setting.wiServiceSettings.findIndex(
          (x) => x.id === data.id,
        );
        if (data.startAtStartUp == undefined) {
          data.startAtStartUp = false;
        }

        setting.wiServiceSettings[index] = data;
      }
      props.onSafe(setting);
    }
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const checkPrice = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.serviceLabel === value);

    if (foundItem !== undefined) {
      if (foundItem.serviceLabel === props.data?.serviceLabel) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Dit label bestaat al'));
    }
    return Promise.resolve();
  };

  return (
    <Modal
      title="Voeg een windows service configuratie toe"
      open={props.open}
      onOk={() => form.submit()}
      okText="Opslaan"
      cancelText="Annuleren"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Annuleer
        </Button>,

        <Button
          key="link"
          type="primary"
          onClick={() => {
            setRemoveItem(true);
            form.submit();
          }}
        >
          Verwijder
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Opslaan
        </Button>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600, height: 'auto' }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Label"
          name="serviceLabel"
          rules={[
            {
              required: true,
              message: messageValidation,
            },
            { validator: checkPrice },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Beschrijving" name="description">
          <Input />
        </Form.Item>
        <Form.Item
          label="Online source (url)"
          name="sourceRemote"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Lokaal source (map)"
          name="sourceLocal"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Service naam"
          name="serviceName"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Publiceerbestandsnaam map"
          name="publishSelectionName"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Service map"
          name="serviceDirectory"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Sln naam"
          name="slnName"
          rules={[{ required: true, message: messageValidation }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Starten bij opstarten"
          name="startAtStartUp"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
}
