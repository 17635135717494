import {
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { SoftwareSetting } from '../../../Models/SoftwareManager/SoftwareSettings';
import { UserModel } from '../../../Models/Auth/UserModel';
import { UserType } from '../../../Enums/UserType';

import { InfoCircleOutlined } from '@ant-design/icons';

import style from './AddUser.module.css';
import Link from 'antd/es/typography/Link';
import { useTranslation } from 'react-i18next';

const RemoveButton = (props: {
  bindedFunction: Function;
  bindedForm: FormInstance;
}) => {
  return (
    <>
      <Button
        key="link"
        type="primary"
        onClick={() => {
          props.bindedFunction(true);
          props.bindedForm.submit();
        }}
      >
        Verwijder
      </Button>
    </>
  );
};

export function AddUser(props: {
  allData?: UserModel[];
  data?: UserModel;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onRemove: Function;
  onEdit: boolean;
  actionLoading: boolean;
  configUser: Function;
}) {
  const [form] = Form.useForm();
  const { i18n, t } = useTranslation();

  const [removeItem, setRemoveItem] = useState(false);
  const [user, SetUser] = useState<UserModel>();

  useEffect(() => {
    form.resetFields();
    SetUser(props.data);
    setRemoveItem(false);
  }, [form, props.data, user]);

  const GetUserTypes = () => {
    return [
      { value: UserType.AdminUser, label: 'Administrator' },
      { value: UserType.NormalUser, label: 'Normale gebruiker' },
    ];
  };

  const ConfigUser = (user?: UserModel) => {
    props.configUser(user);
    props.onCancel();
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (values: UserModel) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    }

    if (removeItem) {
      props.onRemove(values);
    } else {
      props.onSafe(values);
    }
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const checkLabelExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.email === value);
    if (foundItem !== undefined) {
      if (foundItem.id === props.data?.id) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Deze email bestaat al'));
    }
    return Promise.resolve();
  };

  const RenderRemoveButton = () => {
    if (user?.id !== undefined && user.userType != UserType.SuperUser) {
      return (
        <RemoveButton
          bindedFunction={setRemoveItem}
          bindedForm={form}
        ></RemoveButton>
      );
    }
    return <></>;
  };

  return (
    <Modal
      title={t('addauser')}
      open={props.open}
      onOk={() => form.submit()}
      okText={t('safe')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('cancel')}
        </Button>,
        <>
          {RenderRemoveButton()}

          <Button
            key="submit"
            type="primary"
            loading={props.actionLoading}
            onClick={() => form.submit()}
          >
            {t('safe')}
          </Button>
        </>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Emailadres"
          name="email"
          key="email"
          required
          tooltip="Na het aanmaken van een gebruiker kunt u dit veld niet meer wijzigen. Maak dan een nieuwe gebruiker aan"
          rules={[
            {
              required: true,
              message: 'Email is vereist',
            },
            { validator: checkLabelExist },
          ]}
        >
          <Input disabled={props.onEdit ? true : false} />
        </Form.Item>
        <Form.Item
          label="Naam"
          name="name"
          key="name"
          rules={[{ required: true, message: 'Vul een naam in' }]}
        >
          <Input />
        </Form.Item>
        {user?.userType != UserType.SuperUser && (
          <Form.Item
            label="Gebruikerstype"
            name="userType"
            key="userType"
            rules={[{ required: true, message: 'Selecteer een type' }]}
          >
            <Select options={GetUserTypes()}>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
          </Form.Item>
        )}

        {!user?.configured && (
          <Space align="center" className={style.spaceAlignContainer}>
            <InfoCircleOutlined />
            Nog niet geconfigureerd.
            <Button onClick={() => ConfigUser(user)} type="link">
              Stuur instel link
            </Button>
          </Space>
        )}
      </Form>
    </Modal>
  );
}
