import { BehaviorSubject, Observable } from 'rxjs';
import { SoftwareSettingHelper } from '../Helpers/SoftwareSettingHelper';
import { SoftwareSetting } from '../Models/SoftwareManager/SoftwareSettings';

export class SoftwareSettingService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public softwareSettings!: Array<SoftwareSetting>;

  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
  }

  private async FetchItems() {
    if (!this.FetchingReady) {
      this.FetchingReady = true;
      this.softwareSettings = await SoftwareSettingHelper.GetSoftwareSetting();
      this.FetchingReadySubject.next(true);
    }
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject;
  }

  // get overview
  public GetSoftwareSettings() {
    return this.softwareSettings;
  }

  public GetSoftwareSetting(item: string) {
    var index = this.softwareSettings.findIndex(
      (x) => x.softwareLabel === item,
    );
    return this.softwareSettings[index];
  }

  // update overview
  public UpdateSoftwareSetting(item: SoftwareSetting) {
    var index = this.softwareSettings.findIndex((x) => x.id === item.id);
    this.softwareSettings[index] = item;
    SoftwareSettingHelper.UpdateSoftwareSetting(item);
  }

  // add overview
  public async AddSoftwareSetting(newItem: SoftwareSetting) {
    var createdItem = await SoftwareSettingHelper.AddSoftwareSetting(newItem);
    this.softwareSettings.push(createdItem);
  }

  // remove overview
  public DeleteSoftwareSetting(item: SoftwareSetting) {
    var index = this.softwareSettings.findIndex((x) => x.id === item.id);
    this.softwareSettings.splice(index, 1);
    SoftwareSettingHelper.DeleteSoftwareSetting(item);
  }
}
