import { Button, Form, FormInstance, Input, Modal, Select, Space } from 'antd';
import { useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';

import Link from 'antd/es/typography/Link';
import { BackupInterval } from '../../Models/Backup/BackupInterval';
import { RetentionPeriod } from '../../Models/Backup/RetentionPeriod';
import { BackupConfig } from '../../Models/Backup/BackupConfig';

const RemoveButton = (props: {
  bindedFunction: Function;
  bindedForm: FormInstance;
}) => {
  return (
    <>
      <Button
        key="link"
        type="primary"
        onClick={() => {
          props.bindedFunction(true);
          props.bindedForm.submit();
        }}
      >
        Verwijder
      </Button>
    </>
  );
};

export function EditConfigBackup(props: {
  data?: BackupConfig;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onEdit: boolean;
}) {
  const [form] = Form.useForm();

  const [backup, setBackup] = useState<BackupConfig>();

  useEffect(() => {
    // form.resetFields();
    setBackup(props.data);
  }, [form, props.data, backup]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (values: BackupConfig) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    }

    props.onSafe(values);
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const GetBackupInterval = () => {
    return [
      { value: BackupInterval.Weekly, label: 'Wekelijks' },
      { value: BackupInterval.TwoWeekly, label: 'Twee wekelijks' },
      { value: BackupInterval.Monthly, label: 'Maandelijks' },
    ];
  };

  const GetRetentionPeriod = () => {
    return [
      { value: RetentionPeriod.TwoWeekly, label: 'Twee wekelijks' },
      { value: RetentionPeriod.Monthly, label: 'Maandelijks' },
      { value: RetentionPeriod.SixMonthly, label: 'Zes maanden' },
      { value: RetentionPeriod.Yearly, label: 'Jaarlijks' },
    ];
  };

  return (
    <Modal
      title="Bewerk backup configuratie"
      open={props.open}
      onOk={() => form.submit()}
      okText="Opslaan"
      cancelText="Annuleren"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Annuleer
        </Button>,
        <>
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Opslaan
          </Button>
        </>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 18 }}
        style={{
          height: 'auto',
          marginTop: '20px',
        }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Backup interval"
          name="backupInterval"
          key="backupInterval"
        >
          <Select options={GetBackupInterval()}>
            <Select.Option value="backupInterval">""</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Bewaartermijn"
          name="retentionPeriod"
          key="retentionPeriod"
        >
          <Select options={GetRetentionPeriod()}>
            <Select.Option value="retentionPeriod">""</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Backup locatie" name="backupLocation">
          <Input placeholder={'Laat leeg for standaard locatie'} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
