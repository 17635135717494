import { Button, Result } from "antd";
import { ResultStatusType } from "antd/es/result";

  const RenderResultBare = (
    typeAlert: ResultStatusType,
    title: string,
    subtitle: string,
  ) => {
    return (
      <Result
        status={typeAlert}
        title={title}
        subTitle={subtitle}
        extra={[

        ]}
      />
    );
  };

  export default RenderResultBare;