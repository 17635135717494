import React, { useEffect, useState } from 'react';
import { serviceBundler } from '../../App';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  Row,
  Space,
  Typography,
  theme,
} from 'antd';
import HeaderBar from '../HeaderBar';
import { Content, Header } from 'antd/es/layout/layout';
import {
  PlusCircleOutlined,
  DatabaseOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import style from './SoftwareManager.module.css';
import { AddPlatform } from './forms/AddPlatform';
import { SoftwareSetting } from '../../Models/SoftwareManager/SoftwareSettings';
import { useNavigate } from 'react-router-dom';
import { CustomPanelLine } from './SoftwarePlatforms/panels/CustomPanelLine';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;
const { useToken } = theme;

const SoftwareManager = () => {
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const [softwareSettings, setSoftwareSettings] = useState<SoftwareSetting[]>(
    [],
  );
  const [softwareSetting, setSoftwareSetting] = useState<SoftwareSetting>();

  const navigate = useNavigate();

  const NavigateToLabel = (item: SoftwareSetting) => {
    navigate(`/softwaremanager/${item.softwareLabel}`, {
      state: {},
    });
  };

  const ShowModalForEdit = (item: SoftwareSetting) => {
    setSoftwareSetting(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const showModalForAdd = () => {
    setSoftwareSetting(undefined);
    setIsModalOpen(true);
    setModelEditMode(false);
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const SafeSoftwareSetting = async (props: SoftwareSetting) => {
    if (props.id !== undefined) {
      serviceBundler.softwareSettingService.UpdateSoftwareSetting(props);
    } else {
      if (serviceBundler.softwareSettingService.softwareSettings == null) {
        serviceBundler.softwareSettingService.softwareSettings = [props];
      } else {
        await serviceBundler.softwareSettingService.AddSoftwareSetting(props);
      }
    }
    setIsModalOpen(false);
  };

  const RemoveSoftwareSetting = (props: SoftwareSetting) => {
    serviceBundler.softwareSettingService.DeleteSoftwareSetting(props);
    setIsModalOpen(false);
  };

  useEffect(() => {
    serviceBundler.softwareSettingService
      .GetFetchStatus()
      .subscribe((value) => {
        if (!value) {
          return;
        }
        var settings =
          serviceBundler.softwareSettingService.GetSoftwareSettings();
        if (settings !== undefined) {
          setSoftwareSettings(settings);
        }
      });
  }, [0]);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentBlock}
        >
          <div className={style.middleBlock}>
            <Divider orientation="left">{t('allsoftwaresys')}</Divider>

            <Collapse className={style.collapseBlock}>
              {softwareSettings.map((item: SoftwareSetting, i) => (
                <Panel
                  collapsible={'icon'}
                  header={CustomPanelLine(
                    item.softwareLabel,
                    <DatabaseOutlined />,
                    <EditOutlined />,
                    NavigateToLabel,
                    ShowModalForEdit,
                    item,
                  )}
                  key={item.id}
                  style={{ alignItems: 'right' }}
                >
                  <p>{item.description}</p>
                  <Space
                    align="start"
                    style={{ width: '100%', justifyContent: 'right' }}
                  >
                    <Button
                      icon={<DatabaseOutlined />}
                      onClick={() =>
                        navigate(`/softwaremanager/${item.softwareLabel}`, {
                          state: {},
                        })
                      }
                    >
                      Navigeer
                    </Button>
                  </Space>
                </Panel>
              ))}
            </Collapse>

            <Space
              direction="horizontal"
              style={{
                width: '100%',
                marginTop: '20px',
                justifyContent: 'right',
              }}
            >
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={showModalForAdd}
              >
                Voeg toe
              </Button>
              <AddPlatform
                allData={softwareSettings}
                data={softwareSetting}
                open={isModalOpen}
                onCancel={handleCancelForm}
                onSafe={SafeSoftwareSetting}
                onRemove={RemoveSoftwareSetting}
                onEdit={isModelEditMode}
              ></AddPlatform>
            </Space>
          </div>
        </Content>
      </Layout>
    </Space>
  );
};

export default SoftwareManager;
