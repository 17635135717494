import React, { useEffect, useState } from 'react';
import { serviceBundler } from '../../App';
import { Button, Card, Col, Collapse, Divider, Layout, Row, Space, theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { PlusCircleOutlined, ArrowsAltOutlined } from '@ant-design/icons';

import style from './LanguagePage.module.css';
import { useNavigate } from 'react-router-dom';
import { RenderCardTitle } from '../../Components/Test/RenderCardTitle';
import HeaderBar from '../../Components/HeaderBar';
import { AddLanguageConfig } from '../../Components/Test/AddLanguageConfig';
import { Language } from '../../Models/Language/Language';
import { useTranslation } from 'react-i18next';

const { useToken } = theme;

const LanguagePage = () => {
  const { i18n, t } = useTranslation();
  const { token } = useToken();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const [softwareSettings, setSoftwareSettings] = useState<Language[]>([]);
  const [softwareSetting, setSoftwareSetting] = useState<Language>();

  const navigate = useNavigate();

  const NavigateToLabel = (item: Language) => {
    navigate(`/languages/${item.languageKey}`, {
      state: {},
    });
  };

  const ShowModalForEdit = (item: Language) => {
    setSoftwareSetting(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const showModalForAdd = () => {
    setSoftwareSetting(undefined);
    setIsModalOpen(true);
    setModelEditMode(false);
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const SafeSoftwareSetting = async (props: Language) => {
    if (props.id !== undefined) {
      serviceBundler.languageService.UpdateLanguage(props);
    } else {
      if (serviceBundler.languageService.languages == null) {
        serviceBundler.languageService.languages = [props];
      } else {
        await serviceBundler.languageService.AddLanguage(props);
      }
    }
    setIsModalOpen(false);
  };

  const RemoveSoftwareSetting = (props: Language) => {
    serviceBundler.languageService.DeleteLanguage(props);
    setIsModalOpen(false);
  };

  const RenderTitle = (item: Language) => {
    if (item == undefined) {
      return <></>;
    }

    return RenderCardTitle(
      item.languageKey,
      item.languageName,
      <ArrowsAltOutlined />,
      NavigateToLabel,
      ShowModalForEdit,
      item,
    );
  };

  useEffect(() => {
    serviceBundler.languageService.GetFetchStatus().subscribe((value) => {
      if (!value) {
        return;
      }
      var settings = serviceBundler.languageService.GetLanguages();
      if (settings !== undefined) {
        setSoftwareSettings(settings);
      }
    });
  }, [0]);

  const GetNumberTranslations = (item: Language) => {
    if(item.keysLabels != null){
      return (Object.keys(item.keysLabels).length)
    }
    return 0;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
      <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token?.colorPrimaryBg }}
          className={style.headerBlock}
        >
          <Col className={style.middleBlock}>
            <Divider orientation="left">{t('allavaillangu')}</Divider>

            <Row>
              {softwareSettings.map((item: Language, i) => (
                <Col style={{ width: 300, margin: 20 }}>
                  <Card title={RenderTitle(item)} bordered={false}>
                    {GetNumberTranslations(item)} {t('translations').toLowerCase()}
                  </Card>
                </Col>
              ))}
            </Row>

            <Space
              direction="horizontal"
              style={{
                width: '100%',
                marginTop: '20px',
                justifyContent: 'right',
              }}
            >
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={showModalForAdd}
              >
                {t('add')}
              </Button>
              <AddLanguageConfig
                allData={softwareSettings}
                data={softwareSetting}
                open={isModalOpen}
                onCancel={handleCancelForm}
                onSafe={SafeSoftwareSetting}
                onRemove={RemoveSoftwareSetting}
                onEdit={isModelEditMode}
              ></AddLanguageConfig>
            </Space>
          </Col>
        </Content>
      </Layout>
    </Space>
  );
};

export default LanguagePage;
