import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Providers/AuthProvider';
import {
  Col,
  Space,
  Layout,
  Button,
  Checkbox,
  Form,
  Input,
  Alert,
  Result,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import HeaderBar from '../../Components/HeaderBar';

import style from './LoginPage.module.css';
import { AuthResultFromServer } from '../../Providers/AuthResultFromServer';
import { useEffect, useState } from 'react';
import { serviceBundler } from '../../App';
import { PasswordResetHelper } from '../../Helpers/PasswordResetHelper';
import Link from 'antd/es/typography/Link';
import { UserModelSalt } from '../../Models/Auth/UserModelSalt';
import { useTranslation } from 'react-i18next';

const { useToken } = theme;

interface AuthFormData {
  email: string;
  password: string;
}

const RenderLink = (bindedFunction: Function, textLink: string) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'start' }}>
      <Link
        style={{
          marginBottom: '10px',
          marginLeft: '130px',
          color: '#C1D202',
        }}
        onClick={() => bindedFunction()}
      >
        {textLink}
      </Link>
    </div>
  );
};

const RenderForgetPassword = (bindedFunction: Function, textLink: string) => {
  return (
    <Link
      style={{
        marginBottom: '20px',
        marginLeft: '80px',
        color: '#C1D202',
      }}
      onClick={() => bindedFunction()}
    >
      {textLink}
    </Link>
  );
};

const RenderAlert = (showAlert: boolean, alertMessage: string) => {
  if (!showAlert) {
    return;
  }
  return (
    <Alert
      className={style.renderPositionAlert}
      message={alertMessage}
      showIcon
      type="error"
    />
  );
};

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  const [form] = Form.useForm();

  const [alertLogin, showAlertLogin] = useState(false);
  const [alertPasswordReset, showAlertPasswordReset] = useState(false);
  const [passwordResetDone, showPasswordResetDone] = useState(false);
  const [passwordReset, showPasswordReset] = useState(false);

  const [loadingForResult, setLoadingForResult] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (serviceBundler.authService.GetCurrentEmail() != null) {
      navigate('/profile');
    }
  }, [0]);

  const handleLogin = (result: AuthResultFromServer) => {
    if (result.passwordCorrect) {
      navigate(from, { replace: true });
    } else {
      showAlertLogin(true);
    }
    setLoadingForResult(false);
  };

  const BackToHome = () => {
    navigate('/');
  };

  const handleSubmit = (values: AuthFormData) => {
    if (passwordReset) {
      return;
    }

    setLoadingForResult(true);
    var newAuthData = new UserModelSalt();
    newAuthData.email = values.email;
    newAuthData.password = values.password;

    auth.signin(newAuthData, handleLogin);
  };

  const handlePasswordReset = async (values: AuthFormData) => {
    showAlertPasswordReset(false);
    setLoadingForResult(true);

    var result = await PasswordResetHelper.ResetPassword(values.email);
    if (result === undefined) {
      showAlertPasswordReset(true);
    } else {
      showPasswordResetDone(true);
    }
    setLoadingForResult(false);
  };

  const onFinish = (values: AuthFormData) => {
    if (passwordReset) {
      handlePasswordReset(values);
    } else {
      handleSubmit(values);
    }
  };

  const ForgetPassword = () => {
    showPasswordReset(true);
    showAlertLogin(false);
  };

  const Login = () => {
    showPasswordReset(false);
    showAlertPasswordReset(false);
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', height: '100vh' }}
      size={[0, 48]}
    >
      <Layout>
        <HeaderBar></HeaderBar>
        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentBlock}
        >
          <div className={style.middleBlock}>
            {passwordResetDone && (
              <Result
                status="success"
                title="Mail verstuurd naar uw opgegeven adres"
                subTitle="Controleer je email. Mail niet ontvangen? Controleer uw spambox."
                extra={[
                  <Button type="primary" key="console" onClick={BackToHome}>
                    Ga verder
                  </Button>,
                ]}
              />
            )}

            {!passwordResetDone && (
              <>
                {RenderAlert(alertLogin, 'Wachtwoord onjuist')}
                {RenderAlert(alertPasswordReset, 'Email bestaat niet')}

                <Form
                  name="basic"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 16 }}
                  className={style.formWidth}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  onChange={() => {
                    showAlertLogin(false);
                    showAlertPasswordReset(false);
                  }}
                  form={form}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginLeft: '-130px',
                    }}
                  >
                    {passwordReset && RenderLink(Login, 'Inloggen')}
                  </div>

                  <Form.Item
                    label={t('emailadress')}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Geef je gebruikersnaam',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  {!passwordReset &&
                    RenderForgetPassword(
                      ForgetPassword,
                      'Wachtwoord vergeten?',
                    )}

                  {!passwordReset && (
                    <Form.Item
                      label={t('password')}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Geef je wachtwoord',
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  )}

                  {!passwordReset && (
                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      className={style.rememberText}
                    >
                      <Checkbox>{t('rememberme')}</Checkbox>
                    </Form.Item>
                  )}

                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingForResult}
                  >
                    {!passwordReset && t('login')}
                    {passwordReset && 'Stuur reset mail'}
                  </Button>
                </Form>
              </>
            )}
          </div>
        </Content>
      </Layout>
    </Space>
  );
};

export default LoginPage;
