import React, { useEffect, useState } from 'react';
import { Col, Divider, Layout, Row, Space, theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';

import style from './InfoPage.module.css';
import { useNavigate } from 'react-router-dom';
import { VersionInfoHelper } from '../../Helpers/VersionInfoHelper';
import { LocalHelper } from '../../Helpers/LocalHelper';
import { VersionInfoApi } from '../../Models/VersionInfo/VersionInfoApi';
import { VersionInfoApp } from '../../Models/VersionInfo/VersionInfoApp';
import Paragraph from 'antd/es/typography/Paragraph';
import HeaderBar from '../../Components/HeaderBar';

const { useToken } = theme;

const InfoPage = () => {
  let navigate = useNavigate();
  const { token } = useToken();

  const [versionInfoApi, setVersionInfoApi] = useState<VersionInfoApi>();
  const [versionInfoApp, setVersionInfoApp] = useState<VersionInfoApp>();

  useEffect(() => {
    FetchVersionInfo();
  }, [0]);

  const FetchVersionInfo = async () => {
    setVersionInfoApi(await VersionInfoHelper.GetVersionInfo());
    setVersionInfoApp(LocalHelper.GetVersionInfo());
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token?.colorPrimaryBg }}
          className={style.headerBlock}
        >
          {' '}
          <Row>
            <Col span={12} offset={6} className={style.collapseBlock}>
              <Divider orientation="left">Versie info API</Divider>
              <div className={style.contentText}>
                <Paragraph>
                  Versienummer: {versionInfoApi?.versionNumber}
                  <br />
                  Releasedatum: {versionInfoApi?.releaseDate.toString()}
                  <br />
                  Releasenotes:
                  <br />
                  {versionInfoApi?.releaseNotes.map((item: string, i) => (
                    <p> - {item}</p>
                  ))}
                </Paragraph>
              </div>

              <br />

              <Divider orientation="left">Versie info App</Divider>
              <div className={style.contentText}>
                <Paragraph>
                  Versienummer: {versionInfoApp?.VersionNumber}
                  <br />
                  Releasedatum: {versionInfoApp?.ReleaseDate.toString()}
                  <br />
                  Releasenotes:
                  <br />
                  {versionInfoApp?.ReleaseNotes.map((item: string, i) => (
                    <p> - {item}</p>
                  ))}
                </Paragraph>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Space>
  );
};

export default InfoPage;
