import { WebSocketMessageType } from "../../../Enums/WebSocketMessageType";
import { PublishSoftwareType } from "./PublishSoftwareType";

export class WsPublishCommand {
  public Type: WebSocketMessageType;

  public SoftwareLabel: string = "";
  public ItemLabel: string = "";

  public SubType: PublishSoftwareType;

  constructor(subType: PublishSoftwareType) {
    this.Type = WebSocketMessageType.WsPublishCommand;
    this.SubType = subType;
  }
}
